import React from 'react'

import {
    Box,
    Flex,
    Link,
    Text
} from '@chakra-ui/react'

const InvoicesList = ({ data }) => {
    return (
        <Box>
            {data.map((invoice, i) =>
                <Flex
                    justify='space-between'
                >
                    <Text
                        color='gray.200'
                        fontWeight='700'
                        fontSize='22px'
                        w='100px'
                    >{`#${i}`}</Text>
                    <Text>{invoice.amount_HT}€</Text>
                    <Text>{invoice.amount_TTC}€</Text>
                    <Text>{invoice.created_at}</Text>
                    <Link
                        as='a'
                        target='_blank'
                        rel='noopener'
                        href={invoice.receipt_url}
                    >
                        Télécharger
                    </Link>

                </Flex>
            )}
        </Box>
    )
}

export default InvoicesList