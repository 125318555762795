import React from 'react'
import { Box } from '@chakra-ui/react'
const NicePhoneNumbers = ({ props, children }) => {
    const formatNumber = (number) => {
        if (!number) {
            return '-'
        }
        let indicatif = number.substring(3, 0)
        let flag = ''
        switch (indicatif) {
            case '+30':
                flag = '🇬🇷'
                break;
            case '+31':
                flag = '🇳🇱'
                break;
            case '+32':
                flag = '🇧🇪'
                break;
            case '+33':
                flag = '🇫🇷'
                break;
            case '+34':
                flag = '🇪🇸'
                break;
            case '+39':
                flag = '🇫🇮'
                break;
            case '+41':
                flag = '🇨🇭'
                break;
            case '+43':
                flag = '🇦🇹'
                break;
            case '+44':
                flag = "🇬🇧"
                break;
            case '+45':
                flag = '🇩🇰'
                break;
            case '+46':
                flag = '🇸🇪'
                break;
            case '+47':
                flag = '🇳🇴'
                break;
            case '+48':
                flag = '🇵🇱'
                break;
            case '+49':
                flag = '🇩🇪'
                break;
            default:
                break;
        }

        return `${flag}   ${number}`

    }
    return (
        <>
            {formatNumber(children)}
        </>
    )
}

export default NicePhoneNumbers