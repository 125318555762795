/* eslint-disable */
import React from 'react'
import { injectIntl } from 'react-intl'
import AdLanscapeAdmin from '../ad/LandscapeAdmin'
import { useIntl } from 'react-intl'
const siteConfig = require('../../../config');

const AdsShortList = (props) => {
    // console.log( 'props', props)
    const locale = useIntl()['locale']
    const FullDate = (props) => {
        const date = new Date(props.date);
        return (
            date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
        )
    }

    return(
        props.data.map( ad =>
            // ad.status === 'publish' ?
                <AdLanscapeAdmin
                    to={ad.slugFr}
                    id={ad._id}
                    slug={[ ad.slugFr, ad.slugEn ]}
                    contentful_id={ ad.contentful_id }
                    handleDelete={
                        () => {
                            // console.log( 'props.setDeletingAdId( ad.id )')
                            props.setDeletingAdId( ad._id )
                        }
                    }
                    openAction={
                        () => {
                            // this.setState({ adSingleID: edge.sys.id })
                            console.log('open')
                        }
                    }
                    isInIframe={false}
                    name={locale === 'fr' ? ad.titleFr : ad.titleEn}
                    price={ad.price}
                    brand={ null}
                    region={ad.department}
                    key={`landscapelarge-${ad._id}`}
                    images={ad.pictures}
                    seller={ false}
                    publicationDate={<FullDate date={ad.created_at} />}
                    gaViews={
                        ad.gaViews
                    }
                    messages_count={ ad.messages_count }
                />
            // : null
        )
    )
}
export default injectIntl( AdsShortList )