import { Box, Button, HStack, Image } from '@chakra-ui/react'
import axios from 'axios'
import * as React from 'react'
import { BiCheck, BiRotateLeft, BiRotateRight } from 'react-icons/bi'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import Debug from '../../Forms/Debug'
import config from '../../../../config'
import { FormattedMessage } from 'react-intl'


export const ProfilPictRotate = ({ userId, document, rotateCallback }) => {

    const [suffix, setSuffix] = React.useState()

    const handleRotate = (angle) => {
        axios.put(
            `${config.apiUrl}/document/rotate`,
            {
                file: document.split('?')[0],
                angle: angle
            }
        )
            .then(res => handleRefreshImage());
    }

    const handleRefreshImage = () => setSuffix(Date.now());
    const handleValidate = () => rotateCallback()

    return (

        <>

            <Box
                position={'relative'}
                key={suffix}

            >
                <Image
                    w='xl'
                    src={`${config.apiUrl}/document/${document}${suffix ? `&refresh=${suffix}` : ''}`}
                // onClick={() => setActive(true)}
                />
                <HStack
                    w='full'
                    justify={'space-between'}
                    p={6}
                    position='absolute'
                    bottom={0}
                >
                    <Button
                        onClick={() => handleRotate(-90)}
                    >
                        <BiRotateLeft />
                    </Button>
                    <Button
                        onClick={handleValidate}
                        leftIcon={<BiCheck />}
                    >
                        <FormattedMessage id='main.validate' />
                    </Button>
                    <Button
                        onClick={() => handleRotate(90)}
                    >
                        <BiRotateRight />
                    </Button>
                </HStack>
            </Box>
        </>

    )
}