import { Box, Button, Center, Image } from '@chakra-ui/react'
import axios from 'axios'
import * as React from 'react'
import { BiCrop } from 'react-icons/bi'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import Debug from '../../Forms/Debug'
import config from '../../../../config'
import { FormattedMessage } from 'react-intl'


export const ProfilPictCropper = ({ userId, document, cropCallback }) => {

    const [crop, setCrop] = React.useState({ aspect: 1 });
    const [active, setActive] = React.useState();

    const [originImage, setOriginImage] = React.useState();

    const pictImage = React.createRef();

    const validateCrop = () => {

        axios.post(
            `${config.apiUrl}/document/crop`,
            {
                ...crop,
                file: document.split('?')[0],
                coeff:
                    originImage.naturalWidth
                    /
                    originImage.clientWidth
            }
        )
            .then(res => cropCallback());
    }

    return (

        <>
            <ReactCrop
                crop={crop}
                onChange={c => setCrop(c)}
                aspect={1}
                src={`${config.apiUrl}/document/${document}`}


                onImageLoaded={image => setOriginImage(image)}

            />

            <Center
                p={4}
            >
                {crop ?
                    <Button
                        onClick={validateCrop}
                        leftIcon={<BiCrop />}
                    >
                        <FormattedMessage id='main.validate' />
                    </Button>
                    : null}
            </Center>
        </>
    )
}