import React, { useContext, useState } from 'react'
import axios from 'axios'
import IntPhoneInput from '../ad/IntlPhoneNumber'
import { Box, Button, FormControl, FormLabel, Input, Heading, Stack, SimpleGrid, Text, Textarea } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { ProfilePictWizard } from './ProfilePict/Wizard'
import { ProfilePict } from './ProfilePict/View'

import { UserContext } from '../userContext';
import Debug from '../../utils/Debug'
import { Field, Form, Formik } from 'formik'
import LocationField from "../Form/LocationField"
import config from '../../../config'

import * as Yup from 'yup';


const UpdateContactForm = (props) => {
    const userContext = useContext(UserContext);
    const user = userContext ? userContext.user : null;

    const
        [userName, setUserName] = useState(props.user.name),
        [userPhone, setUserPhone] = useState(props.user.phone),
        [isUpdated, setIsUpdate] = useState(false),
        [isRecording, setIsRecording] = useState(false);

    const handleUpdate = (values) => {
        axios.patch(
            `${config.apiUrl}/user/${props.user._id}`,
            values
        )
            .then(function (response) {
                console.log(response);
                setIsUpdate(false)
                // this.props.onUpdate(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required')
    });

    return (

        user ?
            <Stack spacing="1rem">
                {/* <Debug data={user} /> */}
                <Heading mb={2} fontSize={22}>
                    {/* <FormattedMessage id="account.nav.setttings.your.contact" />&nbsp;: */}
                    Votre profil
                </Heading>
                <ProfilePict
                    filePath={user.profilePict}
                    size='100'
                />
                <ProfilePictWizard
                    userId={props.user._id}
                    document={user && user.profilePict ? user.profilePict : null}
                // pictProcessedCallback={() => getData()}
                />

                <Formik
                    initialValues={{
                        ...user,
                        birthDate: user && user.birthDate ? user.birthDate.slice(0, 10) : null
                    }}
                    onSubmit={(values) => handleUpdate(values)}
                    validationSchema={SignupSchema}
                >
                    {({ values, errors, setFieldValue }) => (
                        <Form>
                            <Stack spacing={6}>
                                <Field name='name'>
                                    {({ field, meta }) => (
                                        <FormControl
                                            isInvalid={meta.touched && meta.error}
                                        >
                                            <FormLabel htmlFor="name"><FormattedMessage id="form.step9.name.and.contact" />&nbsp;:</FormLabel>
                                            <Input {...field} />
                                        </FormControl>
                                    )}
                                </Field>

                                <FormControl>
                                    {/* <FormLabel htmlFor="email">Votre nom :</FormLabel> */}
                                    <IntPhoneInput
                                        value={userPhone}
                                        updatePhoneNumber={(value) => {
                                            setFieldValue('phone', value)
                                        }}
                                    />
                                </FormControl>

                                <Field name='presentation'>
                                    {({ field, meta }) => (
                                        <FormControl>
                                            <FormLabel htmlFor="presentation">
                                                <FormattedMessage id="account.settings.short.presentation" />&nbsp;:
                                            </FormLabel>
                                            <Textarea {...field} maxW='100%' />
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name='birthDate'>
                                    {({ field, meta }) => (
                                        <FormControl>
                                            <FormLabel htmlFor="presentation">
                                                <FormattedMessage id="account.settings.birth.date" />&nbsp;:
                                            </FormLabel>
                                            <Input {...field} type='date' />
                                        </FormControl>
                                    )}
                                </Field>

                                {user && user.location ?
                                    <Field name='location'>
                                        {({ field, meta }) => (
                                            <FormControl>
                                                <FormLabel htmlFor="presentation">
                                                    <FormattedMessage id="account.settings.your.location" />&nbsp;:
                                                </FormLabel>
                                                <LocationField
                                                    initialValue={user.location.city ? user.location.city : ''}
                                                    setFieldValue={(key, value) =>
                                                        setFieldValue(
                                                            `location.${key}`,
                                                            value
                                                        )
                                                    }
                                                    location={user.location}
                                                    enableConfirmation={false}
                                                />
                                            </FormControl>
                                        )}
                                    </Field>
                                    : null}
                                <Box>
                                    <Button
                                        type='submit'
                                    >
                                        <FormattedMessage id='main.save' />
                                    </Button>
                                </Box>
                            </Stack>
                        </Form>
                    )}
                </Formik>


                <Text>
                    <FormattedMessage id="account.nav.setttings.email.update.notice" />
                    {/* Pour mettre à jour votre adresse email, faites la demande à vhf@wanaboat.fr */}
                </Text>
            </Stack>
            : null
    )
}

export default UpdateContactForm