import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from '@reach/router'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import TimeAgo from '@jshimko/react-time-ago';
import {
    Box,
    Button,
    Flex,
    Stack,
    Text,
} from '@chakra-ui/react'
import frenchStrings from '@jshimko/react-time-ago/lib/language-strings/fr-short';
import buildFormatter from '@jshimko/react-time-ago/lib/formatters/buildFormatter';
import MessageForm from './MessageForm'
import AuthService from '../../../services/auth.service'
import authHeader from '../../../services/auth-header'

import BuyerAndSellerContact from './BuyerAndSellerContact'
import { MessageGallery } from '../Messages/gallery';
import Debug from '../../utils/Debug';
import AdCardLandscapeSmaller from '../ad/cardLandscapeSmaller';
import { BiLeftArrowAlt } from 'react-icons/bi';

const formatter = buildFormatter(frenchStrings);

const DiscussionDetails = (props) => {
    const [data, setData] = useState();
    const { userId } = props
    useEffect(() => {
        getDiscussionData();
        setDiscussionIsRead();
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            //   console.log('This will run every 10 seconds')
            getDiscussionData()
        }, 10000);
        return () => clearInterval(timer);
    }, []);

    const getDiscussionData = () => {
        axios.get(`${process.env.GATSBY_API_URL}/discussion/${props.id}`)
            .then((response => setData(response.data)))
    }

    const setDiscussionIsRead = () => {
        const userId = AuthService.getCurrentUser().userId
        // alert( userId )
        console.log('authHeader', authHeader())
        axios.patch(
            `${process.env.GATSBY_API_URL}/discussion/${props.id}/set-is-read`,
            { userId: AuthService.getCurrentUser()['userId'] },
            { headers: authHeader() }
        );
    }

    const setContactsAreExchanged = () => {
        const userId = AuthService.getCurrentUser().userId
        // alert( userId )
        console.log('authHeader', authHeader())
        axios.patch(
            `${process.env.GATSBY_API_URL}/discussion/${props.id}`,
            {
                userId: AuthService.getCurrentUser()['userId'],
                isContactExchanged: true
            },
            { headers: authHeader() }
        ).then((response) => getDiscussionData());

    }

    return (
        <Box>
            {/* <Debug data={data.data} /> */}

            <Stack spacing={3}>

                <Box>
                    <Button
                        as={RouterLink}
                        to='..'
                        leftIcon={<BiLeftArrowAlt />}
                    >
                        <FormattedMessage id='main.back' />
                    </Button>
                </Box>

                {data && data.data.ad ?
                    <AdCardLandscapeSmaller
                        name={data.data.ad.titleFr}
                        price={data.data.ad.price}
                        to={null}
                        region={data.data.ad.region}
                        images={{ url: data.data.ad.pictures }}
                        isNavigable={false}
                    />
                    : null}

                {data && data.data.isContactExchanged ?
                    <Box>
                        <BuyerAndSellerContact
                            sender={data.data.sender}
                            receiver={data.data.receiver}
                            userId={userId}
                        />
                    </Box>
                    : null}
                {data ?
                    data.messages.map(message =>

                        <Box key={message._id} id={message._id}>
                            <Flex
                                justify={(message.user === userId) ? "flex-end" : "flex-start"}
                            >
                                <Stack
                                    p={4}
                                    w={{ base: '92%', lg: '75%' }}
                                    borderRadius={4}
                                    backgroundColor={message.user === userId ? "blue.brand" : "gray.200"}
                                    color={(message.user) === userId ? "white" : "gray.800"}
                                >
                                    <Text fontSize="xs" mb=".25rem">
                                        {message.user.name}{' '}
                                        <TimeAgo date={message.createdAt} formatter={formatter} />
                                    </Text>
                                    {message.files ?
                                        <MessageGallery pictures={message.files} />
                                        : null}
                                    <div dangerouslySetInnerHTML={{ __html: message.content.replace(/\n/g, "<br />") }} />
                                </Stack>
                            </Flex>
                            {/* {
                            (
                                message.user.id === userId
                                && message.createdAt !== message.updatedAt
                                // Feature launch
                                && new Date(message.updatedAt).getTime() > new Date("2020-02-02 16:30:00").getTime()
                            ) ? 
                                <Text mt={1} fontSize="xs" textAlign="right">
                                    ✓ <FormattedMessage id="account.message.is.read" />
                                    <TimeAgo date={message.updatedAt} formatter={ formatter } />
                                </Text>
                            : null
                        } */}
                        </Box>
                    )

                    : null}

                {data && data.data.ad.user === userId && !data.data.isContactExchanged ?
                    <Flex
                        justify='center'
                    >
                        <Text
                            fontSize='12px'
                        >
                            <FormattedMessage id="account.buyer.contact.notice.1" />{' '}

                            <Button
                                size='sm'
                                variant='link'
                                onClick={(e) => setContactsAreExchanged()}
                            >
                                <FormattedMessage id="account.buyer.contact.button" />
                            </Button>
                            .{' '}<FormattedMessage id="account.buyer.contact.notice.2" />
                        </Text>
                    </Flex>
                    : null}
            </Stack>
            <Box>
                <MessageForm
                    discussionID={props.id}
                    discussion={data}
                    userID={userId}
                    refreshDiscussion={() => { getDiscussionData() }}
                />
            </Box>

        </Box>
    )
}

export default DiscussionDetails