import React, { Component } from "react";
import { Link as RouterLink } from '@reach/router'
import Discussion from "../Messages/discussion";
import Scrollchor from 'react-scrollchor'
import { Link as GatsbyLink } from 'gatsby'


import TimeAgo from '@jshimko/react-time-ago';
import frenchStrings from '@jshimko/react-time-ago/lib/language-strings/fr-short';
import buildFormatter from '@jshimko/react-time-ago/lib/formatters/buildFormatter';

import { FormattedMessage } from 'react-intl'
import axios from 'axios'
import {
  Badge,
  Button,
  Box,
  Flex,
  Heading,
  Grid,
  Text,
  Image,
  Stack,

  Spinner,
  Tabs
} from "@chakra-ui/react";
import DiscussionsPagination from "./DiscussionsPagination";
// import { NonceProvider } from "react-select";

const formatter = buildFormatter(frenchStrings);

var dateFormat = require("dateformat");
dateFormat.i18n = {
  dayNames: [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi"
  ],
  monthNames: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Janvier",
    "FÃ©vrier",
    "Mars",
    "Avirl",
    "Mai",
    "Juin",
    "Juillet",
    "AoÃ»t",
    "Septembre",
    "Octobre",
    "Novembre",
    "Decembre"
  ],
  timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"]
};

class DiscussionsList extends Component {
  constructor(props) {
    super(props);
    this.handler = this.handler.bind(this);
    this.state = {
      isLoaded: false,
      discussions: [null],
      isDiscussionDisplayed: false,
      discussionDisplayedID: null,
      page: 1
    };
  }

  getDiscussionsData = () => {
    axios.get(
      `${process.env.GATSBY_API_URL}/discussion/user/${this.props.userID}/${this.props.archived ? `archived/` : ''}${this.state.page}`
    ).then((response) => {
      this.setState({
        discussions: response.data.data,
        info: response.data.info,
        isLoaded: true
      });
    })
  }
  componentDidMount() {
    this.getDiscussionsData();
  }
  // componentDidUpdate(prevProps) {
  //   Object.keys(prevProps)
  //     .filter(key => {
  //       return prevProps[key] !== this.props[key];
  //     })
  //     .map(key => {
  //       if (key === 'userID') {
  //         this.getDiscussionsData();
  //       }
  //     });

  // }

  componentWillUnmount() {
    // clearInterval(this.intervalID);
  }

  handler() {
    this.setState({
      isDiscussionDisplayed: false
    })
  }

  openDiscussion = (id, ad) => {
    this.setState({
      isDiscussionDisplayed: true,
      discussionDisplayedID: id,
      discussionDisplayedAd: ad,

    })
  }

  render() {
    const { discussions, isDiscussionDisplayed } = this.state;
    const { userID } = this.props
    const DiscussionCard = ({ id, discussion }) => {
      if (!discussion.sender || !discussion.receiver) { return <Box>Discussion supprimée</Box> }
      return (
        <Box
          display={this.props.adId && this.props.adId !== discussion.ad._id ? 'none' : 'block'}
          as={RouterLink}
          to={this.props.adId ? `../../${discussion._id}` : discussion.isArchived ? `../${discussion._id}` : `./${discussion._id}`}
          key={discussion.id}
          data-discussion-id={discussion.id}
          cursor="pointer"
          backgroundColor="gray.50"
          borderRadius="md"
          p={2}
          border='solid 1px'
          borderColor={discussion.unreadMessage > 0 ? "green.200" : "gray.200"}
          bg={discussion.unreadMessage > 0 ? "green.100" : "gray.100"}
          opacity={!discussion.isArchived ? 1 : '0.5'}
          _hover={{
            borderColor: 'blue.300',
          }}
          fontSize={{ base: "12px", xl: "14px" }}
        >
          <Grid
            w="100%"
            gridTemplateColumns={{
              base: '50px 1fr 140px'
            }}
            gap='10px'
          >
            {
              discussion.ad
                && discussion.ad.pictures
                && discussion.ad.status === 'publish'
                ?
                <Box>
                  <Image
                    loading='lazy'
                    borderRadius='full'
                    w='50px'
                    h='50px'
                    mr={{ base: 2, lg: 3 }}
                    objectFit='cover'
                    src={`https://i3sh32xk.twic.pics/pictures/${discussion.ad.pictures[0]}?twic=v1/cover=50x50/format=webp/quality=50`} />
                </Box>
                : <Box borderRadius='full' w='50px' h='50px' bg='gray.200'></Box>}
            <Flex
              display={{ base: 'flex', lg: 'none' }}
              alignItems='center'
            >
              <Text>
                {(discussion.receiver._id === userID) ? <FormattedMessage id="account.message.from" /> : <FormattedMessage id="account.message.to" />}
                {(discussion.receiver._id === userID) ? ` ${discussion.sender.name} ` : ` ${discussion.receiver.name} `}
              </Text>
            </Flex>
            <Flex
              alignItems='center'
              display={{ base: 'none', lg: 'flex' }}
            >
              <Text>{' '}
                {(discussion.receiver._id === userID) ? <FormattedMessage id="account.message.from" /> : <FormattedMessage id="account.message.to" />}
              </Text>
              <Text
                fontWeight="semibold"
                whiteSpace="pre-wrap"
              >
                {(discussion.receiver._id === userID) ? ` ${discussion.sender.name} ` : ` ${discussion.receiver.name} `}
              </Text>

              <FormattedMessage id="account.message.for" />
              <Heading
                as='div'
                isTruncated={true}
                w={{ lg: '380px', xl: '420px' }}
                display='block'
                fontSize='14px'
                fontWeight='normal'
                textDecor='underline'
                ml='3px'
              >
                {process.env.GATSBY_LANG === 'fr' ? discussion.ad.titleFr : discussion.ad.titleEn}
              </Heading>
              {" "}

            </Flex>
            <Flex
              alignItems='center'
              textAlign='right'
            >
              <Text w='100%'>
                <TimeAgo
                  date={discussion.updatedAt}
                  formatter={process.env.GATSBY_LANG === 'fr' ? formatter : undefined}
                />
              </Text>
            </Flex>
          </Grid>
        </Box>
      )
    }
    const DiscussionsSummary = () => {
      if (!discussions) {
        return <Flex key={0} height="80px" width="100%" borderRadius="sm" boxShadow="sm" justify="center" background="white" alignItems="center"><Spinner color="blue.600" /></Flex>
      }
      if (discussions.length === 0) {
        return (<Text><FormattedMessage id="account.message.you.have.not.been.contacted" /></Text>)
      }
      return (
        <>
          <Box>
            <Stack spacing={3} shouldWrapChildren={true}>
              {discussions.length && discussions.map(discussion => (
                // !discussion.isArchived ?
                <DiscussionCard
                  id={discussion._id}
                  key={discussion._id}
                  discussion={discussion}
                />
                // : null
              ))}
            </Stack>
          </Box>
        </>
      );
    };

    return (
      <Box id='top'>

        {/* <pre>
          { JSON.stringify( this.props , null, 2 )}
        </pre> */}

        {this.state.isLoaded ? (
          <DiscussionsSummary />
        ) : (
          <Flex key={0} height="80px" width="100%" borderRadius="sm" boxShadow="sm" justify="center" background="white" alignItems="center"><Spinner color="blue.600" /></Flex>
        )}
        {this.state.info && this.state.info.count > 10 ?
          <Box
            mt={4}
          >
            <DiscussionsPagination
              count={this.state.info.count}
              currentPage={this.state.page}
              handleChange={(page) => {
                // console.log('update page', page);
                this.setState({ page: page, discussions: [], isLoaded: false }, () => this.getDiscussionsData());
                document.querySelector('#anchor-ads-link').click();
              }}

            />
          </Box>
          : null}

        {this.state.info && this.state.info.hasArchived && !this.props.archived ?
          <Flex justifyContent='flex-start' my={4}>
            <Button size="sm" as={GatsbyLink} to='./archived'>
              <FormattedMessage id="account.see.archived.discussions" />
            </Button>
          </Flex>
          : null}

        {/* <Box>
          <Stack spacing={3} shouldWrapChildren={true}>
            {discussions.length && discussions.map(discussion => (
              discussion.isArchived ?
                <DiscussionCard
                  id={discussion._id}
                  key={discussion._id}
                  discussion={discussion}
                /> : null
            ))}
          </Stack>
        </Box> */}
        <Box display="none">
          <Scrollchor id="anchor-ads-link" to="#top">top</Scrollchor>
        </Box>

      </Box>
    );
  }
}

export default DiscussionsList;