import { Box, Button, Center, Flex, Input, Progress, Spinner, Text } from '@chakra-ui/react';
import axios from 'axios';
import * as React from 'react'
import { BiCloudUpload } from 'react-icons/bi';

import config from '../../../config'
import { FormattedMessage } from 'react-intl';


export const MessageJoinFilesUploadButton = ({ discussionId, document, uploadCallback, saveProfilPictFileName }) => {
    const fileInput = React.useRef()
    const [uploadProgress, setUploadProgress] = React.useState(0);

    var axiosParams = {
        onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            )
            setUploadProgress(percentCompleted);
        },
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }

    const proceedUpload = async file => {
        let profilPictFileName = `image-${discussionId}-${Date.now()}`;
        var formData = new FormData();
        formData.append("document", file);
        formData.append("public", true);
        formData.append("cache", true);
        formData.append("directory", 'd');
        formData.append("resize", true);

        formData.append('outputName', profilPictFileName)
        const imagePosting = await axios.post(
            `${config.apiUrl}/document/${discussionId}`,
            formData,
            axiosParams
        );
        if (imagePosting) {
            uploadCallback(imagePosting.data.Key);
            setUploadProgress(false);
        }
    }


    const handleChange = async e => {
        const { files } = e.target;

        for (let index = 0; index < files.length; index++) {
            const file = files[index];

            setTimeout(
                function () {
                    proceedUpload(file);
                }
                , index * 800);

        }
    }

    return (

        <>
            <Box>
                <Button
                    as='label'
                    htmlFor='fileInput'
                    // size='xs'
                    leftIcon={<BiCloudUpload />}
                    cursor='pointer'
                    isDisabled={uploadProgress > 0}
                    isLoading={uploadProgress > 0}
                >
                    <FormattedMessage id='account.join.file.join.and.upload' />
                </Button>
            </Box>
            <input
                style={{

                    border: '0',
                    clip: 'rect(0 0 0 0)',
                    height: '1px',
                    margin: '-1px',
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    width: '1px',
                }}
                id='fileInput'
                multiple
                type='file'
                name='file'
                onChange={handleChange}
                ref={fileInput}
            />
        </>

    )
}