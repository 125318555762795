/* eslint-disable */
import React, { useState } from 'react'
import { Link as GatsbyLink, navigate } from 'gatsby'
import Griddle, { plugins, RowDefinition, ColumnDefinition } from 'griddle-react'
// import Moment from 'react-moment'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'

import {
    Box,
    Button,
    Flex,
    Heading,
    Image,
    Text,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Stack
} from "@chakra-ui/react";

const siteConfig = require('../../../config');

const AdsTable = (props) => {

    const [isDeleteWizard, toggleDeleteWizard] = useState(false);
    const [adToDeleteID, setAdToDeleteID] = useState(false);


    const rowDataSelector = (state, { griddleKey }) => {
        return state
            .get('data')
            .find(rowMap => rowMap.get('griddleKey') === griddleKey)
            .toJSON();
    };

    const enhancedWithRowData = connect((state, props) => {
        return {
            rowData: rowDataSelector(state, props)
        };
    });

    const AdsListLayout = ({ Table }) => (
        <div>
            {/* <Filter /> */}
            <Table style={{ width: '100%' }} />
        </div>
    );
    const ColumnAlignRight = ({ title }) => <Box display={{ base: "none", lg: "initial" }}><Text textAlign="right">{title}</Text></Box>;
    const ColumnAlignCenter = ({ title }) => <Box display={{ base: "none", lg: "initial" }}><Text textAlign="center">{title}</Text></Box>;
    const ColumnAlignLeft = ({ title }) => <Text textAlign="left">{title}</Text>;

    // const TitleFormat = ({ value }) => <Heading fontSize="14px" fontWeight="bold" isTruncated>{value}</Heading>;
    const TitleFormat = ({ value, griddleKey, rowData }) =>
        <Popover trigger="hover" placement="left">
            <PopoverTrigger>
                <Heading
                    cursor="help"
                    fontSize="14px" color="blue.600" w={{ base: "200px", lg: "auto" }} pr={"2rem"} fontWeight="bold" isTruncated>{value}</Heading>
            </PopoverTrigger>
            {rowData.pictures ?
                <PopoverContent border="0" zIndex={4} width="96px" color="white">
                    <Box p={2}>
                        <Image
                            alt={value}
                            loading="lazy"
                            src={`/photos${rowData.pictures[0]}?quality=70&fit=cover&width=96&height=96&format=jpeg`}
                        />
                    </Box>
                </PopoverContent>
                : null}
        </Popover>

    // const DateFormat = ({ value }) => <Box display={{ base:"none", lg:"initial"}}><Text textAlign="left" fontSize="13px" color="gray.500"><Moment format="DD/MM/YYYY">{value}</Moment></Text></Box>;
    const PriceFormat = ({ value, rowData }) => <Box display={{ base: "none", lg: "initial" }}><Text onClick={() => { navigate(`update/${rowData.id}`) }} textAlign="right" fontSize="13px">{value ? `${value}€` : "-"}</Text></Box>;
    const ViewsFormat = ({ value }) => <Box display={{ base: "none", lg: "initial" }}><Text textAlign="center" fontSize="13px">{value}</Text></Box>;
    const MessagesFormat = ({ value }) => <Box display={{ base: "none", lg: "initial" }}><Text textAlign="center" fontSize="13px">{value}</Text></Box>;
    const Actions = ({ value, griddleKey, rowData }) =>
        <Flex justify="flex-end" h="32px">
            <Menu>
                <MenuButton as={Button} size="sm" colorScheme="blue" rightIcon="chevron-down">
                    Actions
                </MenuButton>
                <MenuList>
                    <MenuItem as="a" target="_blank" href={`/${rowData['slug_' + props.intl.locale]}`}><FormattedMessage id="main.see" /></MenuItem>
                    <MenuItem onClick={() => { navigate(`update/${rowData.id}`) }}>Modifier </MenuItem>
                    <MenuItem
                        onClick={() => { props.setDeletingAdId(rowData['email_token']) }}
                    >
                        <FormattedMessage id="main.delete" />
                    </MenuItem>
                    {/* <MenuItem as={GatsbyLink} target="_blank" to={`/${rowData.slug}`}>Voir </MenuItem>
                    <MenuItem onClick={ () => { openUpdate( rowData.id ) } }>Modifier </MenuItem> */}
                    {/* <MenuItem onClick={ () => {
                        // confirmDelete( rowData.email_token )
                    } */}
                </MenuList>
            </Menu>
        </Flex>

    return (
        <>
            <Griddle
                components={{ Layout: AdsListLayout }}
                data={props.data}
                plugins={[plugins.LocalPlugin]}
                pageProperties={{
                    pageSize: 200
                }}
            >
                <RowDefinition>
                    <ColumnDefinition id={`title_${props.intl.locale}`} title={props.intl.formatMessage({ id: 'main.title' })} customHeadingComponent={ColumnAlignLeft} customComponent={enhancedWithRowData(TitleFormat)} order={1} width={"40%"} />
                    {/* <ColumnDefinition id="updated_at"  title="Publication" order={2} width={"20%"} /> */}
                    {/* <ColumnDefinition id="updated_at" customComponent={DateFormat} customHeadingComponent={ColumnAlignLeft} title="Publié le" order={2} width={"15%"} /> */}
                    <ColumnDefinition id="price" title={props.intl.formatMessage({ id: 'ad.price' })} customHeadingComponent={ColumnAlignRight} customComponent={enhancedWithRowData(PriceFormat)} width={"15%"} />
                    <ColumnDefinition id="messages_count" title="Messages" customHeadingComponent={ColumnAlignCenter} customComponent={MessagesFormat} width={"15%"} />
                    <ColumnDefinition id="gaViews" title="Visites" customHeadingComponent={ColumnAlignCenter} customComponent={ViewsFormat} width={"15%"} />
                    <ColumnDefinition id="action" title="/" customHeadingComponent={ColumnAlignRight} customComponent={enhancedWithRowData(Actions)} width={"15%"} />
                </RowDefinition>
            </Griddle>

        </>
    )
}
export default injectIntl(AdsTable)