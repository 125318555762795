import React from 'react'

import {
    Box,
    Text,
    Grid,
    SimpleGrid,
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'

const BuyerAndSellerContact = ({ sender, receiver, userId }) => {
    return (
        <Box
            bg='blue.100'
            p={2}
            border='solid 1px'
            borderColor='blue.200'
            borderRadius='md'
        >
            {/* <pre>
                { JSON.stringify( props, null, 1 )}
            </pre> */}
            <SimpleGrid columns={2}>
                <Box
                    order={sender._id !== userId ? 1 : 2}

                >
                    <Text fontSize='14px' fontWeight='bold'>
                        <FormattedMessage id='account.buyer.contact.card.buyer' />&nbsp;
                    </Text>
                    <Text fontSize='14px'>
                        {` ${sender.name} - ${sender.email} - ${sender.phone ? sender.phone : ''}`}
                    </Text>
                </Box>
                <Box
                    order={sender._id !== userId ? 2 : 1}
                >
                    <Text fontSize='14px' fontWeight='bold'>
                        <FormattedMessage id='account.buyer.contact.card.seller' />&nbsp;
                    </Text>
                    <Text fontSize='14px'>
                        {` ${receiver.name} - ${receiver.email} - ${receiver.phone ? receiver.phone : ''}`}
                    </Text>
                </Box>
            </SimpleGrid>
        </Box>
    )
}

export default BuyerAndSellerContact