import React from 'react'
import DiscussionsList from './DiscussionsList'
import DiscussionDetails from './DiscussionDetails'


const Discussions = ( props ) => {
    return(
        !props.id ?
            <DiscussionsList
                key={props.UserID}
                userID={props.userID}
                adId={props.adId}
                archived={props.archived}
            />
        :
            <DiscussionDetails
                key={props.UserID}
                userId={props.userID}
                id={ props.id }
            />
        
    )
}

export default Discussions