import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import axios from "axios";
// import changeCase from "change-case";
import UploadButton from './UploadButton'
import {
    Badge,
    Box,
    Icon,
    Button,
    Image,
    Text,
    Flex,
    Spinner,
    Stack,
    FormControl,
    FormLabel,
    FormHelperText,

    Progress,
} from "@chakra-ui/react";
import { BiRotateLeft, BiX } from 'react-icons/bi';

const AdEditFormPictures = ({ pictures, handleChange }) => {


    const deletePicture = (picture, index) => {
        let updatedPictures = pictures
        for (var i = 0; i < pictures.length; i++) {
            if (pictures[i] === picture) {
                updatedPictures.splice(i, 1);
            }
        }
        axios.delete(
            `${process.env.GATSBY_API_URL}/media`,
            { data: { path: picture } }
        ).then(
            handleChange(
                updatedPictures
            )
        )
    }

    const extractedImagePath = () => (
        pictures && pictures[0]
            ?
            pictures[0].split('/')[1]
            :
            Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    )

    const rotate = (props) => {
        console.log('rotate', props)
        axios.patch(
            `${process.env.GATSBY_API_URL}/media/rotate`,
            { imagePath: props }
        )

    }

    const isValid = false
    return (
        <Box>
            <Box
                w='100%'
                overflow='auto'
            >
                <Flex spacing={{ base: 2, lg: 4 }} wrap='wrap'>
                    <UploadButton
                        handleUploaded={(fileUrl) => {
                            pictures.push(fileUrl)
                            handleChange(pictures)
                        }}
                        path={extractedImagePath()}
                    />
                    {pictures.map((picture, index) => (
                        <Box
                            key={picture}
                            mr={'.5rem'}
                            mb={'.5rem'}
                            position="relative"
                            borderRadius="12px"
                            borderColor={index === 0 ? "blue.brand" : "gray.50"}
                            border="solid 3px"
                        >
                            <Flex
                                p={'.25rem'}
                                position='absolute'
                                top="0"
                                left="0"
                                width="100%"
                            >
                                <Button size="xs" mr={2} onClick={() => rotate(picture)} data-url={picture}>
                                    <BiRotateLeft />
                                </Button>
                                <Button size="xs" colorScheme="orange" onClick={() => deletePicture(picture, index)} data-index={index}>
                                    <BiX />
                                </Button>
                            </Flex>
                            <Image
                                borderRadius="lg"
                                w="122px"
                                objectFit="cover"
                                src={`${process.env.GATSBY_PICTURES_URL_NO_CACHE}/${picture}?time=${Date.now()}`}
                                alt="Images"
                            />
                        </Box>
                    ))}
                </Flex>
            </Box>
        </Box>
    )
}
export default AdEditFormPictures