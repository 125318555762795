import {
    Box,
    Button,
    Flex,
    HStack,
    Image,
    SimpleGrid,
    VStack
} from '@chakra-ui/react'
import Axios from 'axios'
import * as React from 'react'
import { BiX } from 'react-icons/bi'
import config from '../../../config'

export const MessageJoinFilesList = ({ data, update }) => {

    const handleDelete = (index) => {
        console.log(index)

        const deleteImage = Axios.delete(
            `${config.apiUrl}/document`,
            {
                data: {
                    path: data[index]
                }
            }
        );

        if (deleteImage) {
            let removed = data.filter((item, i) => index !== i ? item : null)
            update(removed);
        }



    }
    if (!data || !data.length) return null
    return (
        <Box
            p={4}
        >
            <HStack
                gap={2}
                flexWrap='wrap'
            >
                {data.map((item, index) =>
                    <Box
                        position={'relative'}
                        key={`messagePicture${index}`}
                        bgColor='gray.800'
                    >
                        <VStack
                            position={'absolute'}
                            p={1}
                        >
                            <Button
                                size='xs'
                                onClick={() => handleDelete(index)}
                                colorScheme='red'
                            >
                                <BiX />
                            </Button>
                        </VStack>
                        <Image
                            src={`${config.imageCdnToolUrl}${item}?twic=v1/contain=80x80/format=webp/quality=50`}
                            rounded={'sm'}
                            w={'80px'}
                            h={'80px'}
                            objectFit='contain'
                        />
                    </Box>
                )}
            </HStack>
        </Box>
    )
}