import React, { Component } from 'react';
import axios from 'axios';

import {
    Box,
    Text,
    Stack,
    Flex,
    Button,
    HStack,
    Select
} from "@chakra-ui/react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { BiX } from 'react-icons/bi';
import { UserContext } from '../userContext';
import Debug from '../../utils/Debug';

const contentful = require("contentful");
const client = contentful.createClient({
    space: process.env.GATSBY_CONTENTFUL_SPACE,
    environment: 'master',
    accessToken: process.env.GATSBY_CONTENTFUL_TOKEN,
});
export const AlertsList = (props, { userID }) => {

    const [alerts, setAlerts] = React.useState()
    const [models, setModels] = React.useState()
    const { user } = React.useContext(UserContext);

    React.useEffect(
        () => {

            if (user && user._id) {
                getUserAlerts();
            }

        }, []
    )

    React.useEffect(
        () => {

            if (alerts && alerts.length) {

                client.getEntries(
                    {
                        content_type: 'model',
                        "sys.id[in]": alerts.map(item => item.modelId).join(',')
                    }
                ).then(
                    // res => _this.setState({ [element.modelId]: entry.fields.name })
                    res => setModels(res.items)

                )

            }


        }, [alerts]
    )

    const getUserAlerts = () => {
        axios.get(
            `${process.env.GATSBY_API_URL}/alert/user/${user ? user._id : ""}`
        ).then(
            (response) => setAlerts(response.data))
    }


    const handleDelete = (alertId) => {
        axios.delete(`${process.env.GATSBY_API_URL}/alert/${alertId}`)
            .then(function (response) {
                if (response) {
                    getUserAlerts();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleUpdate = (alertId, type) => {
        // console.log('handleUpdate', alertId, type);
        axios.put(
            `${process.env.GATSBY_API_URL}/alert/${alertId}`,
            {
                type: type,
                alertId: alertId
            }
        ).then(res => getUserAlerts())

    }
    return (
        <>

            {/* <Debug data={alerts} /> */}
            {/* <Debug data={models} /> */}
            {/* <Debug data={user} /> */}

            {(alerts && models) ?
                <Stack spacing={4}>
                    {/* <Debug data={alerts ? alerts : ''} /> */}
                    <Text><FormattedMessage id="account.alerts.title" />:</Text>
                    <Box>
                        <Stack
                            spacing={4}
                        >
                            {alerts.map(item => (
                                <Flex
                                    key={item._id}
                                    rounded="md"
                                    variant="solid"
                                    // colorScheme="blue"
                                    p={2}
                                    justify='space-between'
                                    boxShadow={'sm'}
                                    alignItems='center'
                                    border='solid 1px'
                                    borderColor={'gray.100'}
                                    wrap={{ base: 'wrap', lg: 'nowrap' }}
                                >
                                    <Text
                                        fontWeight={600}
                                        pb={{ base: 2, lg: 0 }}
                                    >
                                        {models.filter(model => model.sys.id === item.modelId)[0].fields.name}

                                    </Text>

                                    <HStack>
                                        <Select
                                            // display={'none'}
                                            w='4xs'
                                            size='sm'
                                            rounded='md'
                                            onChange={(e) => handleUpdate(item._id, e.target.value)}
                                            placeholder='Select'
                                            value={item.target}
                                        >
                                            <option value={'all'}>
                                                {props.intl.formatMessage({ id: 'account.alert.type.all' })}
                                                {/* <FormattedMessage id="account.alert.type.all" /> */}
                                            </option>
                                            <option value={'boat'}>
                                                {props.intl.formatMessage({ id: 'account.alert.type.boat' })}
                                            </option>
                                            <option value={'other'}>
                                                {props.intl.formatMessage({ id: 'account.alert.type.other' })}
                                            </option>
                                        </Select>
                                        <Button
                                            leftIcon={<BiX />}
                                            size='sm'
                                            onClick={() => handleDelete(item._id)}
                                        >
                                            <FormattedMessage id='main.delete' />
                                        </Button>
                                    </HStack>
                                </Flex>
                            ))}
                        </Stack>
                    </Box>
                    <Text><FormattedMessage id="account.alerts.notice" /></Text>
                </Stack>
                : <Text><FormattedMessage id="account.alerts.no.alert.notice" /></Text>}
        </>
    )
}


export default injectIntl(AlertsList)