import React, { useState, useEffect } from 'react'

import {
    Box,
    Flex,
    Spinner
} from '@chakra-ui/react'
import Axios from 'axios'
import { Line } from 'react-chartjs-2';


const AdVisitsStats = ({ slug, id }) => {
    const [adStats, setAdStats] = useState(false)
    useEffect(() => {
        Axios.get(`${process.env.GATSBY_API_URL}/stat/ad/${id}`)
            .then((response) => setAdStats(response.data))
    }, [])
    const getAllKeys = (array) => {
        var keys = []
        for (let index = 0; index < array.length; index++) {
            keys.push(array[index][0])
        }
        return keys
    }

    const getAllValues = (array) => {
        var values = []
        var total = 0
        for (let index = 0; index < array.length; index++) {
            total = total + parseInt(array[index][1])
            values.push(total)
        }
        return values
    }

    const data = {
        labels: getAllKeys(adStats),
        legend: {
            display: 'none'
        },
        datasets: [
            {
                label: 'Visites',
                fill: true,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: getAllValues(adStats)
            }
        ]
    };
    return (
        <Box
            mt={{ base: 2, lg: 4 }}
        >
            {data && adStats ?
                <Line options={{ legend: { display: false } }} data={data} />
                :
                <Flex
                    py={{ base: 1, lg: 2 }}
                    justifyContent='center'
                >
                    <Spinner size='md' />
                </Flex>
            }

            {/* { adStats ?
                <Area
                    data={adStats}
                    options={{
                        // maintainAspectRatio: props.aspectRatio,
                        width: '100%',
                        height: '100%',
                        title: { display: false },
                        legend: { display: false },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    // max : 1,    
                                    min: 0
                                }
                            }]
                        }
                    }}
                />
                : null} */}
        </Box>
    )
}

export default AdVisitsStats