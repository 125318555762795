import React, { useState, useEffect } from "react"
import { Link as GatsbyLink, navigate } from 'gatsby'
import LayoutAccount from "../components/layouts/layoutAccount"
import { Box, Button, Grid, Flex, Image, List, ListItem, Spinner } from "@chakra-ui/react"
import { FormattedDate, FormattedMessage } from 'react-intl';
import Seo from '../components/Seo/';
import { CSSTransition } from 'react-transition-group';

import Discussions from '../components/userAccount/discussions'
import Ads from '../components/userAccount/Ads'
import YourAccount from '../components/userAccount/yourAccount'
import YourAlerts from '../components/Alerts/AlertsList'
import Settings from '../components/userAccount/settings'
import Invoices from '../components/account/invoices'

import AdEditForm from '../components/Form/AdEditForm'
import './transition.css'
import { Router, Link as NavLink, Match } from "@reach/router";
import logo from '../images/logo.svg'
import AuthService from '../../services/auth.service'
import { BiLeftArrowAlt } from "react-icons/bi";
const siteConfig = require('../../config')

const AccountTpl = (props) => {

  const [UserID, setUserID] = useState(false)
  const [UserData, setUserData] = useState(false)

  useEffect(() => checkLoginCredentials(), [])

  const checkLoginCredentials = () => {
    if (AuthService.getCurrentUser()) {
      setUserID(AuthService.getCurrentUser()['userId'])
    } else {
      navigate('/identification/');
    }
    // if (
    //     localStorage.getItem('UserID') === null
    //     || localStorage.getItem('WbUserToken') === null
    //   ) {
    //   navigate('/identification/');
    // } else {
    //   let userID = localStorage.getItem('UserID');
    //   let userToken = localStorage.getItem('WbUserToken');
    //   setUserID(userID)
    //   axios.get(`${process.env.GATSBY_API_URL}users/${userToken}`)
    //     .then(function (response) {
    //       setUserData(response.data)
    //       console.log( 'setUserData', response.data )
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // }
  }

  const [isMobileNavVisible, setMobileNavVisible] = useState(false)
  const title = {
    "fr": "Votre compte, messagerie, gestion de vos annonce et réglages"
    ,
    "en": "Your account, inbox, discussions & ad management and settings"
  }
  const description = {
    "fr": "Sur cette page vous pourrez accéder à la gestion de vos annonce et aux discussions avec d'autres membres de Wanaboat."
    ,
    "en": "On this page you will be able to access the management of your ads and discussions with other members of Wanaboat."
  };

  const InactiveButton = (props) => {
    return (
      <Button
        variant='ghost'
        w='100%'
        color="gray.600"
        justifyContent='flex-start'
        as={NavLink}
        onClick={() => { setMobileNavVisible(false) }}
        {...props}>{props.children}
      </Button>
    )
  }

  const ActiveButton = (props) => {
    return (
      <Button
        variant='ghost'
        w='100%'
        justifyContent='flex-start'
        bg='gray.50'
        as={NavLink}
        color="blue.brand"
        onClick={() => { setMobileNavVisible(false) }}
        {...props}>{props.children}
      </Button>
    )
  }

  const AccountNav = () => {
    return (
      <Box
        position="sticky"
        top="2rem"
        p='1rem'
        backgroundColor='white'
        pb='7rem'
        w='100%'
      >
        <List>
          <ListItem>
            <Match path={siteConfig.links.account[process.env.GATSBY_LANG]}>
              {props =>
                props.match ? (
                  <ActiveButton to={siteConfig.links.account[process.env.GATSBY_LANG]}>
                    <FormattedMessage id="account.nav.welcome" />
                  </ActiveButton>
                ) : (
                  <InactiveButton to={siteConfig.links.account[process.env.GATSBY_LANG]}>
                    <FormattedMessage id="account.nav.welcome" />
                  </InactiveButton>
                )
              }
            </Match>
          </ListItem>
          <ListItem>
            <Match path={`${siteConfig.links.account[process.env.GATSBY_LANG]}discussions/`}>
              {props =>
                props.match ? (
                  <ActiveButton to={`${siteConfig.links.account[process.env.GATSBY_LANG]}discussions/`}>
                    <FormattedMessage id="account.nav.discussions" />
                  </ActiveButton>
                ) : (
                  <InactiveButton to={`${siteConfig.links.account[process.env.GATSBY_LANG]}discussions/`}>
                    <FormattedMessage id="account.nav.discussions" />
                  </InactiveButton>
                )
              }
            </Match>
          </ListItem>
          <ListItem>
            <Match path={`${siteConfig.links.account[process.env.GATSBY_LANG]}annonces/`}>
              {props =>
                props.match ? (
                  <ActiveButton to={`${siteConfig.links.account[process.env.GATSBY_LANG]}annonces/`}>
                    <FormattedMessage id="account.nav.classifieds" />
                  </ActiveButton>
                ) : (
                  <InactiveButton to={`${siteConfig.links.account[process.env.GATSBY_LANG]}annonces/`}>
                    <FormattedMessage id="account.nav.classifieds" />
                  </InactiveButton>
                )
              }
            </Match>
          </ListItem>
          <ListItem>
            <Match path={`${siteConfig.links.account[process.env.GATSBY_LANG]}alerts/`}>
              {props =>
                props.match ? (
                  <ActiveButton to={`${siteConfig.links.account[process.env.GATSBY_LANG]}alerts/`}>
                    <FormattedMessage id="account.nav.alerts" />
                  </ActiveButton>
                ) : (
                  <InactiveButton to={`${siteConfig.links.account[process.env.GATSBY_LANG]}alerts/`}>
                    <FormattedMessage id="account.nav.alerts" />
                  </InactiveButton>
                )
              }
            </Match>
          </ListItem>
          <ListItem>
            <Match path={`${siteConfig.links.account[process.env.GATSBY_LANG]}settings/`}>
              {props =>
                props.match ? (
                  <ActiveButton to={`${siteConfig.links.account[process.env.GATSBY_LANG]}settings/`}>
                    <FormattedMessage id="account.nav.settings" />
                  </ActiveButton>
                ) : (
                  <InactiveButton to={`${siteConfig.links.account[process.env.GATSBY_LANG]}settings/`}>
                    <FormattedMessage id="account.nav.settings" />
                  </InactiveButton>
                )
              }
            </Match>
          </ListItem>
          {UserData.invoices ?
            <ListItem>
              <Match path={`${siteConfig.links.account[process.env.GATSBY_LANG]}invoices/`}>
                {props =>
                  props.match ? (
                    <ActiveButton to={`${siteConfig.links.account[process.env.GATSBY_LANG]}invoices/`}>
                      {/* <FormattedMessage id="account.nav.settings" /> */}
                      Factures ({UserData.invoices.length})
                    </ActiveButton>
                  ) : (
                    <InactiveButton to={`${siteConfig.links.account[process.env.GATSBY_LANG]}invoices/`}>
                      {/* <FormattedMessage id="account.nav.settings" /> */}
                      Factures ({UserData.invoices.length})
                    </InactiveButton>
                  )
                }
              </Match>
            </ListItem>
            : null}
        </List>
      </Box>
    )
  }

  const AdsPanel = (props) => {
    return (<Ads userID={UserID ? UserID : 'UserID'} />)
  }

  const AdUpdate = ({ id }) => {
    return (
      id ?
        <AdEditForm
          adId={id}
          finishEditingAd={console.log('ad edit OK')}
          cancel={() => { navigate('../../') }}
        />
        : null
    )
  }

  const DiscussionsListWrapper = (props) => {
    if (props.userID) {
      return <Discussions {...props} />
    } else {
      return <Spinner size='sm' />
    }
  }

  return (
    <LayoutAccount lang={process.env.GATSBY_LANG}>
      <Seo
        title={
          title[process.env.GATSBY_LANG]
        }
        description={
          description[process.env.GATSBY_LANG]

        }
        slug={''}
      />
      <Box>
        <Flex
          p={4}
          borderBottom={{ base: 'none', lg: 'solid 1px' }}
          borderBottomColor={{ lg: 'gray.100' }}
          justifyContent='space-between'
        >
          <Box>
            <Button size='sm' as={GatsbyLink} to="/" variant="outline">
              ← <FormattedMessage id="main.quit.your.account" />
            </Button>
          </Box>
          <Box>
            <Image src={logo} w="30px" h="30px" />
          </Box>
        </Flex>
        <Flex
          position='fixed'
          bottom={0}
          right={0}
          left={0}
          p={4}
          display={{ base: 'flex', lg: 'none' }}
          w='full'
          justify='space-between'
          zIndex='tooltip'


        >
          <Button
            as={NavLink}
            to={siteConfig.links.account[process.env.GATSBY_LANG]}
            colorScheme='blue'
            leftIcon={<BiLeftArrowAlt />}
          >
            <FormattedMessage id='main.back' />
          </Button>
          <Button
            colorScheme='blue'
            onClick={() => { setMobileNavVisible(!isMobileNavVisible) }}
          >
            Menu
          </Button>
        </Flex>

        <Grid
          maxW='75rem'
          mx='auto'
          templateColumns={{
            base: "100%",
            lg: "300px 1fr"
          }}
        >
          <Box
            display={{
              base: isMobileNavVisible ? 'flex' : 'none',
              lg: 'block'
            }}
            alignItems='flex-end'
            position={{ base: 'fixed', lg: 'initial' }}
            bottom='0'
            left='10px'
            w={{ base: 'calc( 100vw - 10px )', lg: 'auto' }}
            h={{ base: '100vh', lg: 'auto' }}
            backgroundColor={{ base: 'rgba(10,10,10,.2)', lg: 'transparent' }}
            zIndex={{ base: 'banner', lg: 'base' }}
          >
            <CSSTransition in={isMobileNavVisible} timeout={200} classNames="my-node">
              <AccountNav />
            </CSSTransition>
          </Box>

          <Box
            borderLeft='solid 1px'
            borderLeftColor='gray.200'
            p={{ base: 4, lg: 8 }}
          >

            {/* <Box>
            UserID { JSON.stringify( UserID, null, 2 ) }
          </Box> */}
            {/* {UserID ? */}

            <Router
              basepath={`${siteConfig.links.account[process.env.GATSBY_LANG]}`}
              default="/"
            >

              <YourAccount path="/" data={UserData} />
              <Invoices path="/invoices" data={UserData.invoices} />
              <DiscussionsListWrapper path="/discussions/" archived={false} userID={UserID} />
              <DiscussionsListWrapper path="/discussions/archived" archived={true} userID={UserID} />
              <DiscussionsListWrapper path="/discussions/:id" userID={UserID} />
              <DiscussionsListWrapper path="/discussions/ad/:adId" userID={UserID} />
              <AdsPanel path="/annonces/" userID={UserID} />
              <AdUpdate path="/annonces/update/:id" userID={UserID} />
              <YourAlerts path="/alerts/" key={UserID} userID={UserID} />
              <Settings
                path="/settings/"
                userID={UserID}
                user={UserData}
                updateUserData={setUserData}
              />
            </Router>
          </Box>
        </Grid>

      </Box>
    </LayoutAccount>
  )
}

export default AccountTpl
