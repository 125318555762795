import React, { useState, useEffect } from "react"
import { Box, Button, Heading, List, ListItem, Stack, Text } from "@chakra-ui/react"
import { FormattedMessage } from 'react-intl';
import { Link as NavLink, Match } from "@reach/router";
import { Link as GatsbyLink } from 'gatsby'
const siteConfig = require('../../../config')
const YourAccount = ({ data }) => {

  console.log('UserData', data);

  const InactiveButton = (props) => {
    return (
      <Button
        variant='ghost'
        w='100%'
        color="gray.600"
        justifyContent='flex-start'
        as={NavLink}
        {...props}>{props.children}
      </Button>
    )
  }

  const ActiveButton = (props) => {
    return (
      <Button
        variant='ghost'
        w='100%'
        justifyContent='flex-start'
        bg='gray.50'
        as={NavLink}
        color="blue.brand"
        {...props}>{props.children}
      </Button>
    )
  }

  const AccountNav = () => {
    return (
      <Box
        position="sticky"
        top="2rem"
        // p='1rem'
        my="2rem"
        backgroundColor='white'
        pb='7rem'
        w='100%'
      >
        <List as='div' spacing={4}>
          {/* <ListItem>
                <Match path={siteConfig.links.account[process.env.GATSBY_LANG]}>
                  {props =>
                    props.match ? (
                      <ActiveButton to={siteConfig.links.account[process.env.GATSBY_LANG]}>
                        <FormattedMessage id="account.nav.welcome" />
                      </ActiveButton>
                    ) : (
                        <InactiveButton to={siteConfig.links.account[process.env.GATSBY_LANG]}>
                          <FormattedMessage id="account.nav.welcome" />
                        </InactiveButton>
                      )
                  }
                </Match>
              </ListItem> */}
          <ListItem as='div' border='solid 2px' borderColor='blue.500' borderRadius='md'>
            <Match path={`${siteConfig.links.account[process.env.GATSBY_LANG]}discussions/`}>
              {props =>
                props.match ? (
                  <ActiveButton to={`${siteConfig.links.account[process.env.GATSBY_LANG]}discussions/`}>
                    <FormattedMessage id="account.nav.discussions" />
                  </ActiveButton>
                ) : (
                  <InactiveButton to={`${siteConfig.links.account[process.env.GATSBY_LANG]}discussions/`}>
                    <FormattedMessage id="account.nav.discussions" />
                  </InactiveButton>
                )
              }
            </Match>
          </ListItem>

          <ListItem as='div' border='solid 2px' borderColor='blue.500' borderRadius='md'>
            <Match path={`${siteConfig.links.account[process.env.GATSBY_LANG]}ads/`}>
              {props =>
                props.match ? (
                  <ActiveButton to={`${siteConfig.links.account[process.env.GATSBY_LANG]}ads/`}>
                    <FormattedMessage id="account.nav.classifieds" />
                  </ActiveButton>
                ) : (
                  <InactiveButton to={`${siteConfig.links.account[process.env.GATSBY_LANG]}ads/`}>
                    <FormattedMessage id="account.nav.classifieds" />
                  </InactiveButton>
                )
              }
            </Match>
          </ListItem>
          <ListItem as='div' border='solid 2px' borderColor='blue.500' borderRadius='md'>
            <Match path={`${siteConfig.links.account[process.env.GATSBY_LANG]}alerts/`}>
              {props =>
                props.match ? (
                  <ActiveButton to={`${siteConfig.links.account[process.env.GATSBY_LANG]}alerts/`}>
                    <FormattedMessage id="account.nav.alerts" />
                  </ActiveButton>
                ) : (
                  <InactiveButton to={`${siteConfig.links.account[process.env.GATSBY_LANG]}alerts/`}>
                    <FormattedMessage id="account.nav.alerts" />
                  </InactiveButton>
                )
              }
            </Match>
          </ListItem>

          <ListItem as='div' border='solid 2px' borderColor='blue.500' borderRadius='md'>
            <Match path={`${siteConfig.links.account[process.env.GATSBY_LANG]}settings/`}>
              {props =>
                props.match ? (
                  <ActiveButton to={`${siteConfig.links.account[process.env.GATSBY_LANG]}settings/`}>
                    <FormattedMessage id="account.nav.settings" />
                  </ActiveButton>
                ) : (
                  <InactiveButton to={`${siteConfig.links.account[process.env.GATSBY_LANG]}settings/`}>
                    <FormattedMessage id="account.nav.settings" />
                  </InactiveButton>
                )
              }
            </Match>
          </ListItem>
        </List>
      </Box>
    )
  }
  return (
    <Box>
      <Box>
        <FormattedMessage id="account.welcome.message" />
        {data.isPro ?
          <Stack
            spacing='1rem'
            mt='1rem'
            bg='blue.brand'
            p='2rem'
            borderRadius='2px'
            color='white'
          >
            <Heading
              fontSize='22px'
            >
              Wanaboat développe votre site internet gratuitement !
            </Heading>
            <Text>
              Nouveauté Automne 2020 : Wanaboat créé des sites internets pour vous, les pros du nautisme. Un site conçu et développé sur mesure pour votre activité. Facile à manipuler et avec des performances en référencemenet Google et en ergonomie au top.
            </Text>
            <Box>
              <Button
                as={GatsbyLink}
                to='/capitainerie/site-pro'
                bg='#FFE660'
                color='gray.700'
                whiteSpace='pre-wrap'
                textAlign='center'
                h='auto'
                py='.5rem'

              >
                Offre de lancement, les premières créations sont offertes →
              </Button>
            </Box>

          </Stack>
          : null}
        <Box display={{ lg: "none" }}>
          <AccountNav />
        </Box>
      </Box>
    </Box>
  )
}

export default YourAccount