import React, { useState } from 'react';
import { navigate } from "gatsby";
import { Link as RouterLink } from '@reach/router'
import { FormattedMessage } from 'react-intl';
import AdVisitsStats from './AdVisitsStats'
import {
  Badge,
  Button,
  Box,
  Grid,
  Flex,
  Heading,
  Icon,
  Image,
  Text,

  SimpleGrid
} from "@chakra-ui/react";

import logo from "../../static/svg/logo.svg"
import { BiCamera, BiEdit, BiEditAlt, BiTrash } from 'react-icons/bi';

const LandscapeAdmin = (props) => {
  const {
    id,
    name,
    images,
    slug,
    handleDelete,
    messages_count,
    gaViews
  } = props

  const displayThumbnail = (images, alt) => {
    if (images && images.length) {
      return (
        <Box
          position="relative"
          zIndex="base"
        >
          <picture>
            <source
              srcSet={
                `${process.env.GATSBY_BASE_IMG_URL}`
                + images[0]
                + '?twic=v1/cover=70x70/format=webp/quality=50'
              }
              type="image/webp"
            />
            <source
              srcSet={
                `${process.env.GATSBY_BASE_IMG_URL}`
                + images[0]
                + '?twic=v1/cover=70x70/format=jpeg/quality=50'
              }
              type="image/jpeg"
            />
            <source
              srcSet={
                `${process.env.GATSBY_BASE_IMG_URL}`
                + images[0]
                + '?twic=v1/cover=70x70/format=heif/quality=50'
              }
              type="image/heif"
            />
            <Image
              src={
                `${process.env.GATSBY_BASE_IMG_URL}`
                + images[0]
                + '?twic=v1/cover=70x70/format=jpeg/quality=50'
              }
              size='70px'
              loading='lazy'
              alt={alt}
            />
          </picture>
          <Flex
            position="absolute" bottom="0" left="0" w={'100%'}
            justify="flex-end"
            borderRadius={'0 0 4px 4px'}
            p={1}
            alignItems="flex-end"
            background="linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, rgba(26, 26, 26, 0.8) 100%)"
          >
            <Text
              as="span"
              fontSize="xs"
              color="white"
              pr={1}
            >
              {images.length}
            </Text>
            <Box
              color='white'
            >
              <BiCamera />
            </Box>
          </Flex>
        </Box>
      )
    }
    else {
      return (
        <picture>
          <Image size='50px' loading="lazy" src={logo} alt={alt} />
        </picture>
      )
    }
  }
  const [showStats, setShowStats] = useState(false)

  return (
    <Box
      className="item-card"
      as={"article"}
      display="block"
      p={{ base: 2, lg: 2 }}
      mb={5}
      background="white"
      shadow="xs"
      borderRadius={3}
      border="solid 2px"
      borderColor={"white"}

    >
      <Grid
        templateColumns={{ base: "100%", lg: "70px calc(100% - 70px - 50px - 30px)" }}
        gridGap={"15px"}>
        <Flex
          alignItems='center'
          display={{ base: 'none', lg: 'flex' }}
          bgColor='gray.100'
        >
          {displayThumbnail(images, name)}
        </Flex>
        <Box>
          <Heading
            as="h4"
            w={'100%'}
            mb='1rem'
            fontWeight="400"
            fontSize={{ base: "md", lg: 'lg' }}
            isTruncated>
            {name}
          </Heading>
          <SimpleGrid
            columns={{ base: 2, lg: 4 }}
            gap={{ base: '.5rem', lg: '1rem' }}
          >
            <Button
              as={RouterLink}
              size='sm'
              w={{ base: '100%', lg: 'auto' }}
              colorScheme='blue'
              to={`./update/${id}`}
            >
              <Box mr={1}>
                <BiEditAlt />
              </Box>
              <FormattedMessage id='main.update' />
            </Button>
            <Button
              onClick={() => { handleDelete() }}
              w={{ base: '100%', lg: 'auto' }}
              size='sm'
              colorScheme='orange'
            >
              <Box mr={1}>
                <BiTrash />
              </Box>
              <FormattedMessage id='main.delete' />
            </Button>

            <Button
              size='sm'
              onClick={() => {
                setShowStats(!showStats)
              }}
              colorScheme='green'
            >
              {gaViews} visites{' '}
              <Text as='span' fontSize='10px'>
                {
                  gaViews > 5000 ? '🏆'
                    : gaViews > 2500 ? '🏅🏅🏅'
                      : gaViews > 2000 ? '🏅🏅'
                        : gaViews > 1500 ? '🏅'
                          : gaViews > 1000 ? '⭐⭐⭐'
                            : gaViews > 500 ? '⭐⭐'
                              : gaViews > 100 ? '⭐'
                                : ''
                }
              </Text>
            </Button>
            <Button
              size='sm'
              as={RouterLink}
              to={`../discussions/ad/${id}`}
            >
              {messages_count} Discussion{messages_count > 0 ? 's' : ''}
            </Button>

          </SimpleGrid>
        </Box>
      </Grid>
      {showStats ?
        <AdVisitsStats
          slug={slug}
          id={id}
        />
        : null}
    </Box>
  )
}

export default LandscapeAdmin
