import { Box, Modal, ModalBody, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import axios from 'axios'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import config from '../../../../config'
import Debug from '../../../utils/Debug'
// import Debug from '../../Forms/Debug'

import { ProfilPictCropper } from './Cropper'
import { ProfilPictRotate } from './Rotation'
import { ProfilPictUploader } from './Uploader'

export const ProfilePictWizard = ({ userId, document, pictProcessedCallback }) => {

    const [step, setStep] = React.useState(1)
    const [profilPictFileName, setProfilPictFileName] = React.useState()

    const recordProfilePict = () => {
        axios.patch(
            `${config.apiUrl}/user/${userId}`,
            {
                profilePict: `u/${userId}/${profilPictFileName}.jpg`
            }
        ).then(res => {
            setStep(1);
            // pictProcessedCallback();
        })

        deletePreviousProfileFile();
    }

    const deletePreviousProfileFile = () => {
        axios.delete(
            `${config.apiUrl}/document/`,
            {
                data: {
                    path: document
                }
            }
        );
    }

    return (
        <>

            {step === 1 ?
                <ProfilPictUploader
                    userId={userId}
                    uploadCallback={(fileName) => {
                        setStep(2);
                        setProfilPictFileName(fileName);
                    }}

                />
                : null}

            {step === 2 ?

                <Modal
                    isOpen={step === 2}
                    onClose={() => console.log('close')}
                >
                    <ModalBody>
                        <ModalOverlay />

                        <ModalContent>
                            <Text
                                fontSize={'sm'}
                                color={'gray.700'}
                                p={4}
                                textAlign='center'
                            >
                                <FormattedMessage id='account.settings.pict.rotate.notice' />

                                Rotate this image by clicking on the button to put it on the right orientation.
                            </Text>
                            <ProfilPictRotate
                                userId={userId}
                                document={`u/${userId}/${profilPictFileName}.jpg?time=${Date.now()}`}
                                rotateCallback={() => setStep(3)}
                            />
                        </ModalContent>
                    </ModalBody>
                </Modal>
                : null}

            {step === 3 ?
                <Modal
                    isOpen={step === 3}
                    onClose={() => console.log('close')}
                >
                    <ModalBody>
                        <ModalOverlay />
                        <ModalContent>
                            <Text
                                fontSize={'sm'}
                                color={'gray.700'}
                                p={4}
                                textAlign='center'
                            >
                                <FormattedMessage id='account.settings.pict.crop.notice' />
                                {/* Click on the image to crop and resize on the face. */}
                            </Text>
                            <ProfilPictCropper
                                userId={userId}
                                document={`u/${userId}/${profilPictFileName}.jpg?time=${Date.now()}`}
                                cropCallback={recordProfilePict}
                            />
                        </ModalContent>
                    </ModalBody>
                </Modal>
                : null}
        </>
    )
}