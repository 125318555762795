import { Box, Button, Flex } from '@chakra-ui/react'
import React from 'react'

const DiscussionsPagination = (
    {
        count,
        currentPage,
        handleChange
    }) => {


    const pagesCount = Math.floor(count / 10);
    const items = []

    for (let index = 1; index < pagesCount + 1; index++) {
        items.push(
            <Box
                key={index}
                mr={2}
            >
                <Button
                    isDisabled={index === currentPage}
                    onClick={() => handleChange(index)}
                >
                    {index}
                </Button>
            </Box>
        )
    }

    return (
        <Flex>
            <Flex
                alignItems='center'
                p={2}
                mr={2}
                backgroundColor='gray.50'
                color='gray.500'

            >Page : </Flex>
            {items}
        </Flex>
    )

}

export default DiscussionsPagination