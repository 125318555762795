import React, { useState, useRef } from 'react'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Textarea,
    Text,
    HStack,
    Stack,
    ButtonGroup
} from '@chakra-ui/react'
import { BiPaperclip, BiPaperPlane } from 'react-icons/bi'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import { MessageJoinFilesList } from './MessageJoinFilesList'
import Debug from '../../utils/Debug'
import { MessageJoinFilesUploadButton } from './MessageJoinFilesUploadButton'

const MessageForm = ({ discussion, userID, refreshDiscussion }) => {
    const [isSendingMessage, setIsSendingMessage] = useState(false)
    const [joinFiles, setJoinFiles] = React.useState([])

    const onSubmit = async (values, { setSubmitting, setErrors, setStatus, resetForm }) => {
        setIsSendingMessage(true);
        resetForm();

        let postingMessage = axios.post(
            `${process.env.GATSBY_API_URL}/message/`,
            {
                user: userID,
                discussion: discussion.data._id,
                content: values.content,
                files: joinFiles
            }
        );

        setJoinFiles([]);
        setIsSendingMessage(false);
        refreshDiscussion();



    }
    return (
        discussion && !discussion.data.isArchived ?
            <Formik
                key={joinFiles}
                onSubmit={onSubmit}
                validationSchema={
                    yup.object().shape({
                        content: yup.string().required('Required'),
                    })
                }
                initialValues={{ content: '' }}
            >
                {({
                    values,
                    touched,
                    errors
                }) => (
                    <Stack
                        as={Form}
                        mt={{ base: 5, lg: 10 }}
                    >
                        <FormLabel htmlFor="content">Message</FormLabel>
                        <Box
                            borderRadius='3px'
                            border='solid 1px '
                            borderColor={'gray.100'}
                        >
                            <MessageJoinFilesList
                                update={
                                    (data) => {
                                        setJoinFiles([...data]);
                                    }
                                }
                                data={joinFiles}
                            />
                            <Field
                                name='content'
                            >
                                {({ field, meta }) => (
                                    <FormControl>
                                        <Textarea
                                            {...field}
                                            required
                                            border='none'
                                            placeholder="Bassine rouge, tricot vert.."
                                            _focus={{
                                                border: 'none'
                                            }}
                                        />
                                        {
                                            discussion.data.ad.user === userID ?
                                                <Flex backgroundColor="gray.500" borderRadius="0 0 3px 3px" p="0.45rem" color="white" fontSize="12px">
                                                    <FormattedMessage id="account.message.velocity.notice" />
                                                </Flex>
                                                :
                                                null
                                        }
                                    </FormControl>
                                )}

                            </Field>
                        </Box>
                        <ButtonGroup
                            justifyContent='flex-end'
                            size='sm'
                        >
                            <MessageJoinFilesUploadButton
                                uploadCallback={(data) => {

                                    let updatedData = joinFiles;
                                    updatedData.push(data)
                                    setJoinFiles([...updatedData])
                                }}
                                discussionId={discussion.data._id}
                            />
                            <Button
                                isLoading={isSendingMessage}
                                type="submit"
                                colorScheme="green"
                                leftIcon={<BiPaperPlane />}
                                isDisabled={!values || !values.content}
                            >
                                <FormattedMessage id="main.send" />
                            </Button>

                        </ButtonGroup>

                    </Stack>
                )}
            </Formik>

            :
            <Box
                fontSize='14px'
                color='gray.500'
                py={5}
                my={5}
                borderTop='solid 1px'
                borderTopColor='gray.100'
                textAlign='center'
            >
                <FormattedMessage id='account.archived.discussion.notice' />
            </Box>

    )
}

export default MessageForm