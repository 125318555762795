import React, { useState } from 'react'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import {
    Box,
    Text,
    Spinner
} from '@chakra-ui/react'

const UploadButton = ({ handleUploaded, path }) => {
    const [progress, setProgress] = useState(false)

    const proceedUpload = form => {

        // console.log('proceed upload')
        var config = {
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                )
                // setProgress( percentCompleted );
            }
        }
        return axios.post(`${process.env.GATSBY_API_URL}/media/`, form, config);
    }

    const handleUpload = e => {
        let files = e.target.files

        console.log(files)
        setProgress(true)
        var form = null;

        for (var index = 0; index < files.length; index++) {
            form = new FormData();
            form.append("path", path);
            const file = files[index];
            form.append(`file`, file);

            proceedUpload(form)
                .then(function (response) {
                    handleUploaded(response.data.key)
                    //   let updatedPictures  = pictures
                    //   updatedPictures.push(response.data.key);
                    //   handleChange( pictures )
                    // setProgress( 0 )
                })
                .catch(function (response) { });
        }
        setProgress(false)
    };

    return (
        <>
            <input
                style={{
                    width: "0.1px",
                    height: "0.1px",
                    opacity: 0,
                    overflow: "hidden",
                    position: "absolute",
                    zIndex: -1
                }}
                onChange={handleUpload}
                type="file"
                id="files"
                name="files"
                multiple
                accept="image/jpeg"
            />
            <Box
                as="label"
                htmlFor="files"
                marginRight=".5rem"
                border="dashed 1px grey"
                borderRadius="10px"
                width="128px"
                height="128px"
                display="flex"
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                _hover={{
                    cursor: "pointer",
                    color: "white",
                    backgroundColor: "blue.brand",
                    borderColor: "blue.brand"
                }}
            >
                {progress ?
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                    /> :
                    <Text
                        fontSize="14px"
                        textAlign="center">
                        <FormattedMessage id="form.step7.select.the.pictures" />
                    </Text>}
            </Box>
        </>
    )
}

export default UploadButton