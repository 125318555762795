import { Box, Button, Center, Flex, Input, Progress, Spinner, Text } from '@chakra-ui/react';
import axios from 'axios';
import * as React from 'react'
import { BiCloudUpload } from 'react-icons/bi';

import Debug from '../../Forms/Debug'
import config from '../../../../config'
import { FormattedMessage } from 'react-intl';


export const ProfilPictUploader = ({ userId, document, uploadCallback, saveProfilPictFileName }) => {
    const fileInput = React.useRef()
    const [uploadProgress, setUploadProgress] = React.useState(0);

    var axiosParams = {
        onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            )
            setUploadProgress(percentCompleted);
        },
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }

    const proceedUpload = file => {
        let profilPictFileName = `profilPict-${Date.now()}`;

        var formData = new FormData();
        formData.append("document", file);
        formData.append("public", true);
        formData.append("cache", true);
        formData.append('outputName', profilPictFileName)
        axios.post(
            `${config.apiUrl}/document/${userId}`
            , formData,
            axiosParams
        ).then(async res => {
            uploadCallback(profilPictFileName);
        })
    }

    const handleChange = e => {
        const file = e.target.files[0];
        proceedUpload(file);
    }

    return (

        <>
            <Box>
                <Button
                    as='label'
                    htmlFor='fileInput'
                    size='xs'
                    leftIcon={<BiCloudUpload />}
                    cursor='pointer'
                >
                    <FormattedMessage id='account.settings.pict.uploader.label' />
                </Button>
            </Box>
            <input
                style={{

                    border: '0',
                    clip: 'rect(0 0 0 0)',
                    height: '1px',
                    margin: '-1px',
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    width: '1px',
                }}
                id='fileInput'
                multiple
                type='file'
                name='file'
                onChange={handleChange}
                ref={fileInput}
            />
        </>

    )
}