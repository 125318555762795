import { Box, Button, Center, Grid, Image, Portal } from '@chakra-ui/react'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import config from '../../../config'
import Debug from '../../utils/Debug'

export const MessageGallery = ({ pictures }) => {
    const [detail, setDetail] = React.useState(null)
    if (!pictures) return
    return (

        <>
            <Grid
                spacing={2}
                flexWrap='wrap'
                gridTemplateColumns={{
                    base: 'repeat(3, 1fr)',
                    lg: 'repeat(6, 1fr)'
                }}
                gridGap={3}
            >
                {/* <Debug data={pictures} /> */}
                {pictures.map((item, index) =>
                    <Box
                        key={`messagePictureDetail-${index}`}
                        cursor='pointer'
                        onClick={() => setDetail(index)}
                    >
                        <Image
                            alt={'image'}
                            src={`${config.imageCdnToolUrl}${item}?twic=v1/contain=100x100/format=webp/quality=50`}
                            w={'100px'}
                            h='100px'
                            objectFit={'contain'}
                            bgColor='gray.900'
                        />
                    </Box>
                )}
            </Grid>
            {detail || detail === 0 ?
                <Portal>
                    <Box
                        position='fixed'
                        top={0}
                        right={0}
                        bottom={0}
                        left={0}
                        bgColor='blackAlpha.900'
                        onClick={() => setDetail(false)}
                    >
                        <Button
                            position={'absolute'}
                            right={10}
                            top={10}
                        >
                            <FormattedMessage id='main.close' />
                        </Button>
                        <Center
                            h={'100vh'}
                        >
                            <Image
                                src={`${config.imageCdnToolUrl}${pictures[detail]}?twic=v1/format=webp/quality=60`}
                                alt="Image details"
                                maxW={'80vw'}
                                maxH={'80vh'}
                            />
                        </Center>
                    </Box>
                </Portal>
                : null}
        </>
    )
}